body.view-viewview.contenttype-successstory .ui.basic.segment.header-wrapper {
  margin: 0;
}
body.view-viewview.contenttype-successstory .lead.image {
  width: 100%;
}
#main .container .block.hero {
  margin: 70px 0px;
}
#main .container .block.hero .block-inner-wrapper {
  flex-wrap: wrap;
  justify-content: center;
}
#main .container .block.hero .block-inner-wrapper > * {
  width: unset;
  flex-grow: 1;
  flex-basis: calc(calc(50rem - 100%) * 999);
}
#main .container .block.hero .block-inner-wrapper .hero-image {
  min-width: calc(30% - 2em);
  max-width: 50%;
  height: 100%;
}
#main .container .block.hero .block-inner-wrapper .hero-body {
  max-height: none;
  min-width: calc(70% - 2em);
  max-width: 100%;
  background-color: unset;
  padding-top: 0px;
}
#main .container .block.hero .block-inner-wrapper .hero-body h1 {
  font-size: 3rem;
}
#main .container .block.hero .block-inner-wrapper .hero-body p {
  font-size: 1.15rem;
  line-height: 2em;
}
#main .container .block.hero.reverse .block-inner-wrapper {
  flex-direction: row-reverse;
}
.ServicesList.ui.cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
}
@media (max-width: 1024px) {
  .ServicesList.ui.cards {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}
.ServicesList.ui.cards .ui.card {
  width: unset;
}
.ServicesList.ui.cards .ui.card a {
  height: 100%;
}
.ServicesList.ui.cards .ui.card a .content {
  height: 100%;
  padding: 2em 1.5em;
}
.ServicesList.ui.cards .ui.card a .content > .header {
  height: 100%;
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
}
@media (max-width: 1000px) {
  .ServicesList.ui.cards .ui.card a .content > .header {
    flex-direction: row;
  }
}
.ServicesList.ui.cards .ui.card a .content > .header i.icon,
.ServicesList.ui.cards .ui.card a .content > .header i.icons {
  font-size: 2em;
  color: #5f5f5f;
  position: absolute;
  right: 0;
}
.ServicesList.ui.cards .ui.card a .content > .header .header-content {
  font-size: clamp(1.8rem, 2vw, 2.3rem);
  color: #5f5f5f;
  font-weight: 400;
  width: calc(100% - 45px);
  line-height: 3rem;
}
#main .ui.cards {
  display: grid;
  gap: 2rem;
}
#main .ui.cards > .card,
#main .ui.cards .ui.card {
  background-color: #fafafa;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  transition: all ease-in-out 0.3s;
  width: unset;
  margin: 0px;
  border-radius: 0.3rem;
}
#main .ui.cards > .card:hover,
#main .ui.cards .ui.card:hover {
  background-color: #f2f2f2;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.26);
}
.contenttype-project #main .content-area main div.content-main-carousel .carousel-root,
.contenttype-product #main .content-area main div.content-main-carousel .carousel-root {
  margin-bottom: 2rem;
}
.contenttype-project #main .content-area main div.content-main-carousel .carousel-root .carousel .control-dots,
.contenttype-product #main .content-area main div.content-main-carousel .carousel-root .carousel .control-dots {
  bottom: -40px;
}
.contenttype-project #main .content-area main div.content-main-carousel .carousel-root .carousel .control-dots .dot,
.contenttype-product #main .content-area main div.content-main-carousel .carousel-root .carousel .control-dots .dot {
  border-radius: 0px;
  box-shadow: unset;
  border: 1px solid #bababa;
  margin: 0px 4px;
}
.contenttype-project #main .content-area main div.content-main-carousel .carousel-root .carousel .control-dots .dot.selected,
.contenttype-product #main .content-area main div.content-main-carousel .carousel-root .carousel .control-dots .dot.selected {
  background-color: #bababa;
}
.contenttype-project #main .content-area main div.content-main-carousel .carousel-root .carousel.carousel-slider,
.contenttype-product #main .content-area main div.content-main-carousel .carousel-root .carousel.carousel-slider {
  overflow: unset;
}
.contenttype-project #main .content-area main div.content-main-carousel .carousel-root .carousel .slide img,
.contenttype-product #main .content-area main div.content-main-carousel .carousel-root .carousel .slide img {
  width: auto;
  max-width: 100%;
}
.contenttype-project #main .content-area main div.header,
.contenttype-product #main .content-area main div.header {
  align-items: baseline;
  justify-content: flex-start;
}
.contenttype-project #main .content-area main div.header h1.title,
.contenttype-product #main .content-area main div.header h1.title {
  width: -moz-max-content;
  width: max-content;
}
.contenttype-project #main .content-area main div.header span.year,
.contenttype-product #main .content-area main div.header span.year {
  margin-left: 10px;
}
.contenttype-project #main .content-area main .metadata .client-label,
.contenttype-product #main .content-area main .metadata .client-label {
  margin-top: 1rem;
}
.contenttype-project #main .content-area main .metadata .client,
.contenttype-product #main .content-area main .metadata .client {
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.contenttype-project #main .content-area main .metadata .client-static,
.contenttype-product #main .content-area main .metadata .client-static {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
  justify-content: flex-end;
}
.contenttype-project #main .content-area main .metadata .client-static a .ui.image,
.contenttype-product #main .content-area main .metadata .client-static a .ui.image {
  filter: grayscale(100%);
  transition: filter 0.5s ease-in-out;
}
.contenttype-project #main .content-area main .metadata .client-static a:hover .ui.image,
.contenttype-product #main .content-area main .metadata .client-static a:hover .ui.image {
  filter: grayscale(0%);
  transition: filter 0.5s ease-in-out;
}
.contenttype-project #main .content-area main .description,
.contenttype-product #main .content-area main .description {
  margin: 1rem 0px;
  line-height: 2rem;
}
.contenttype-project #main .content-area main .service_techno_list,
.contenttype-product #main .content-area main .service_techno_list {
  flex-wrap: wrap;
  margin: 1rem 0px;
}
.contenttype-project #main .content-area main .service_techno_list .column,
.contenttype-product #main .content-area main .service_techno_list .column {
  width: unset;
  min-width: calc(50% - 2em);
  max-width: 100%;
  flex-grow: 1;
  flex-basis: calc(calc(40rem - 100%) * 999);
}
.contenttype-project #main .content-area main .testimonials,
.contenttype-product #main .content-area main .testimonials {
  width: 60%;
  position: relative;
  margin: 2rem 0px;
}
.contenttype-project #main .content-area main .testimonials i.icon,
.contenttype-product #main .content-area main .testimonials i.icon {
  position: absolute;
  top: 12px;
  left: 11px;
  z-index: 2;
  font-size: 100px;
  color: #f0f0f0;
}
.contenttype-project #main .content-area main .testimonials div.ui.card,
.contenttype-product #main .content-area main .testimonials div.ui.card {
  position: relative;
  width: 100%;
  z-index: 1;
  min-height: 6rem;
  margin-bottom: 0px;
}
.contenttype-project #main .content-area main .testimonials div.ui.card div.content,
.contenttype-product #main .content-area main .testimonials div.ui.card div.content {
  padding: 0.5rem 2.7rem;
  position: relative;
}
.contenttype-project #main .content-area main .testimonials div.ui.card div.content p,
.contenttype-product #main .content-area main .testimonials div.ui.card div.content p {
  position: relative;
  z-index: 4;
}
.contenttype-project #main .content-area main .testimonials div.testimonial-author,
.contenttype-product #main .content-area main .testimonials div.testimonial-author {
  text-align: end;
  color: #bababa;
  font-size: 16px;
  padding-right: 15px;
  margin-top: 5px;
}
.listing .projects-listing .ui.cards,
.search .projects-listing .ui.cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 4rem;
}
@media screen and (max-width: 767px) {
  .listing .projects-listing .ui.cards,
  .search .projects-listing .ui.cards {
    gap: 1rem;
  }
}
.listing .projects-listing .ui.cards::after,
.search .projects-listing .ui.cards::after {
  content: unset;
}
.listing .projects-listing .ui.cards a,
.search .projects-listing .ui.cards a {
  position: relative;
}
@media screen and (max-width: 767px) {
  .listing .projects-listing .ui.cards a,
  .search .projects-listing .ui.cards a {
    width: 100%;
  }
}
.listing .projects-listing .ui.cards a .background-image-warper,
.search .projects-listing .ui.cards a .background-image-warper {
  overflow: hidden;
  position: absolute;
  border-radius: 0.3rem;
  inset: 0;
  margin: 0.4rem;
}
.listing .projects-listing .ui.cards a .background-image-warper img.background-image,
.search .projects-listing .ui.cards a .background-image-warper img.background-image {
  position: absolute;
  width: 105%;
  height: 105%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: 50% 50%;
     object-position: 50% 50%;
  pointer-events: none;
  filter: grayscale();
  z-index: 1;
  opacity: 0.2;
}
.listing .projects-listing .ui.cards a .ui.cards > .card,
.search .projects-listing .ui.cards a .ui.cards > .card,
.listing .projects-listing .ui.cards a .ui.card,
.search .projects-listing .ui.cards a .ui.card {
  min-height: 12rem;
  height: -moz-min-content;
  height: min-content;
  padding: 0.7rem 1.4rem;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .listing .projects-listing .ui.cards a .ui.cards > .card,
  .search .projects-listing .ui.cards a .ui.cards > .card,
  .listing .projects-listing .ui.cards a .ui.card,
  .search .projects-listing .ui.cards a .ui.card {
    min-height: 6rem;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
  }
}
.listing .projects-listing .ui.cards a .ui.cards > .card::after,
.search .projects-listing .ui.cards a .ui.cards > .card::after,
.listing .projects-listing .ui.cards a .ui.card::after,
.search .projects-listing .ui.cards a .ui.card::after {
  content: unset;
}
.listing .projects-listing .ui.cards a .ui.cards > .card .header,
.search .projects-listing .ui.cards a .ui.cards > .card .header,
.listing .projects-listing .ui.cards a .ui.card .header,
.search .projects-listing .ui.cards a .ui.card .header {
  font-size: 1.7rem;
  z-index: 3;
}
.listing .projects-listing .ui.cards a .ui.cards > .card .description,
.search .projects-listing .ui.cards a .ui.cards > .card .description,
.listing .projects-listing .ui.cards a .ui.card .description,
.search .projects-listing .ui.cards a .ui.card .description {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-direction: row;
  z-index: 3;
}
.listing .projects-listing .ui.cards a .ui.cards > .card .description .year,
.search .projects-listing .ui.cards a .ui.cards > .card .description .year,
.listing .projects-listing .ui.cards a .ui.card .description .year,
.search .projects-listing .ui.cards a .ui.card .description .year {
  z-index: 3;
}
.listing .projects-listing .ui.cards a .ui.cards > .card .description img.image.mini,
.search .projects-listing .ui.cards a .ui.cards > .card .description img.image.mini,
.listing .projects-listing .ui.cards a .ui.card .description img.image.mini,
.search .projects-listing .ui.cards a .ui.card .description img.image.mini {
  z-index: 3;
  width: 35px;
}
.contenttype-client .cards {
  gap: 2rem;
  margin-top: 2rem;
}
.contenttype-client .cards .card {
  padding: 1rem 2rem;
  width: unset;
}
.figure .statistics {
  justify-content: center;
}
.figure .statistics .statistic {
  padding: 1rem 2rem;
}
.figure .statistics .statistic .value {
  color: #2d5467;
}
.figure .statistics .statistic .label {
  color: #c0ccd1;
}
.client-carousel .carousel .slider-wrapper .slider li.slide {
  display: flex;
  align-items: center;
  flex-flow: row;
}
.client-carousel .carousel .slider-wrapper .slider li.slide .ui.image {
  filter: grayscale(100%);
  transition: filter 0.5s ease-in-out;
}
.client-carousel .carousel .slider-wrapper .slider li.slide:hover .ui.image {
  filter: grayscale(0%);
  transition: filter 0.5s ease-in-out;
}
.client-carousel.mini .carousel .slider-wrapper .slider {
  gap: 10px;
}
.client-carousel.mini .carousel .slider-wrapper .slider li.slide {
  min-width: 20%;
}
.client-carousel.mini .carousel .slider-wrapper .slider li.slide .ui.image {
  width: 80px;
}
.client-carousel.tiny .carousel .slider-wrapper .slider {
  gap: 10px;
}
.client-carousel.tiny .carousel .slider-wrapper .slider li.slide {
  min-width: 20%;
}
.client-carousel.tiny .carousel .slider-wrapper .slider li.slide .ui.image {
  width: 150px;
}
.buttonCTA {
  margin: 1rem 0rem;
  display: inline-block;
}
.buttonCTA a {
  padding: 1rem 2rem;
  background-color: #066190;
  color: white;
  border-radius: 999px;
}
.buttonCTA a:hover {
  background-color: #2e81aa;
}
div.OSM-banner-map .ol-map {
  height: 554px;
}
div.OSM-banner-map .ol-map .ol-overlay-container div {
  position: relative;
}
div.OSM-banner-map .ol-map .ol-overlay-container div img.image.logo {
  position: absolute;
  top: 4px;
  left: 16px;
}
div.OSM-banner-map .ol-map .ol-control {
  opacity: 0;
  transition: opacity 0.5s ease;
}
div.OSM-banner-map .ol-map .ol-zoom {
  top: unset;
  bottom: 0.5rem;
  left: 1rem;
}
div.OSM-banner-map:hover .ol-control {
  opacity: 1;
}
.Teams.ui.cards {
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  clear: both;
  margin-top: 3rem;
}
.Teams.ui.cards .card {
  min-height: clamp(1rem, 9vw, 10rem);
}
.Teams.ui.cards .card .content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 3;
  padding: 2em 1.5em;
}
.Teams.ui.cards .card .content .header {
  font-size: clamp(2rem, 2vw, 3rem);
  color: #2d5467;
}
.Teams.ui.cards .card .content .meta {
  font-size: 1.5rem;
  font-weight: 600;
}
.Teams.ui.cards .card.joinus::before {
  content: '?';
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.1;
  font-size: clamp(7rem, 11vw, 12rem);
  overflow: hidden;
}
.Values.ui.cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
}
@media (max-width: 1024px) {
  .Values.ui.cards {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}
.Values.ui.cards .card:hover content {
  background-color: #f2f2f2;
}
.Values.ui.cards .card .content {
  width: 100%;
  background-color: #fafafa;
  transition: all ease-in-out 0.3s;
}
.Values.ui.cards .card .content .ui.move.up.reveal:hover > .visible.content,
.Values.ui.cards .card .content .ui.move.up.reveal:target > .visible.content,
.Values.ui.cards .card .content .ui.move.up.reveal:active > .visible.content {
  transform: translateY(-85%) !important;
}
.Values.ui.cards .card .content .ui.move.up.reveal .cover {
  height: 230px;
}
.Values.ui.cards .card .content .ui.move.up.reveal .cover img {
  max-width: 100%;
  height: 220px;
  -o-object-fit: cover;
     object-fit: cover;
}
.Values.ui.cards .card .content .ui.move.up.reveal .header {
  justify-content: center;
  align-items: center;
  font-size: clamp(2rem, 2vw, 3rem);
  padding: 0.7rem 0;
}
.Values.ui.cards .card .content .description {
  padding: 5rem 2rem 2rem;
}
.Values.ui.cards .card .tactile.description {
  padding: 1rem;
}
@media (max-width: 768px) {
  .contenttype-client #main img {
    float: unset;
  }
}
.contenttype-client #main .ui.cards.project-list {
  display: flex;
}
@media (max-width: 768px) {
  .contenttype-client #main .ui.cards.project-list {
    flex-direction: column;
  }
}
.carousel.carousel-slider .control-arrow {
  color: black;
  opacity: 0.6;
}
.carousel.carousel-slider .control-arrow.control-prev::before {
  border-right: 8px solid black;
}
.carousel.carousel-slider .control-arrow.control-next::before {
  border-left: 8px solid black;
}
.hamburger-inner,
.hamburger-inner::after,
.hamburger-inner::before {
  background-color: #fff;
}
